import type { Dayjs, OpUnitType } from 'dayjs';

import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Importation de la locale française
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';



// ----------------------------------------------------------------------

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(relativeTime);
dayjs.locale('fr'); // Définition de la locale française

// ----------------------------------------------------------------------

export type DatePickerFormat = Dayjs | Date | string | number | null | undefined;

/**
 * Docs: https://day.js.org/docs/en/display/format
 */
export const formatStr = {
  dateTime: 'DD MMM YYYY HH:mm:ss', //
  date: 'DD MMM YYYY', // 17 Apr 2022
  time: 'h:mm a', // 12:00 am
  split: {
    dateTime: 'DD/MM/YYYY h:mm a', // 17/04/2022 12:00 am
    date: 'DD/MM/YYYY', // 17/04/2022
  },
  paramCase: {
    dateTime: 'DD-MM-YYYY h:mm a', // 17-04-2022 12:00 am
    date: 'DD-MM-YYYY', // 17-04-2022
  },
};

export function today(format?: string) {
  return dayjs(new Date()).startOf('day').format(format);
}

// ----------------------------------------------------------------------

/** output: 17 Apr 2022 12:00 am
 */
export function fDateTime(date: DatePickerFormat, format?: string) {
  if (!date) {
    return null;
  }

  const isValid = dayjs(date).isValid();

  if (isValid) {
    // Get the current date and time in UTC
    const current_date_utc = dayjs().utc().format(format ?? formatStr.dateTime);

    // Calculate the time difference between the current time and UTC time in hours
    const time_diff = dayjs().diff(current_date_utc, 'hour');

    // Adjust the input date by adding the time difference
    return dayjs(date).add(time_diff, 'hour').format(format ?? formatStr.dateTime);
  } else {
    return 'Invalid time value';
  }
}
