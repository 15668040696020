import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  Typography,
  Skeleton,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import {
  useGetFoldersQuery,
  useGetSubFoldersQuery,
  useGetSubFolderDetailsQuery,
} from "@/taskpane/services/folders.hook";
import StyledSelect from "@/taskpane/components/styled-select/StyledSelect";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import HelpIcon from "@mui/icons-material/Help";
import dayjs from "dayjs";

import { fDateTime } from "@/taskpane/utils/format-time";

interface Document {
  name: string;
  uploadTime: string;
  status: string;
  pages?: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const ADDIN_API_BASE_URL = process.env.REACT_APP_BASE_URL_API_ADDIN;

function InfoWidget({ title, value, loading = false }: { title: string; value: string | number; loading?: boolean }) {
  return (
    <Card sx={{ p: 2, minWidth: 100 }}>
      <Typography variant="h6" fontSize={12} color="#000" fontWeight={600}>
        {title}:{" "}
      </Typography>
      {loading ? (
        <Skeleton variant="text" width={100} height={32} />
      ) : (
        <Typography variant="body1" fontSize={12}>
          {value}
        </Typography>
      )}
    </Card>
  );
}

function DocumentsTable({ documents, loading = false }: { documents: Document[]; loading?: boolean }) {
  const computeStatusInFrench = (status: string) => {
    switch (status) {
      case "not_started":
        return "En attente...";
      case "En attente":
        return "En attente...";
      case "success":
        return "Analysé";
      default:
        return "Indeterminé";
    }
  };

  const displayedStatus = (status: string) => {
    switch (status) {
      case "not_started":
        return (
          <IconButton color="warning" size="small">
            <PendingIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        );
      case "En attente":
        return (
          <IconButton color="warning" size="small">
            <PendingIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        );
      case "success":
        return (
          <IconButton color="success" size="small">
            <CheckCircleIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        );
      default:
        return (
          <IconButton color="default" size="small">
            <HelpIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        );
    }
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 0 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="50%" sx={{ py: 2 }}>
              <Typography variant="body2" fontSize={10} fontWeight="medium">
                Nom du document
              </Typography>
            </TableCell>
            <TableCell width="20%" sx={{ py: 2 }}>
              <Typography variant="body2" fontSize={10} fontWeight="medium">
                Date d'ajout
              </Typography>
            </TableCell>
            <TableCell width="15%" sx={{ py: 2 }}>
              <Typography variant="body2" fontSize={10} fontWeight="medium">
                Pages
              </Typography>
            </TableCell>
            <TableCell width="15%" sx={{ py: 2 }}>
              <Typography variant="body2" fontSize={10} fontWeight="medium">
                Statut
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? [...Array(3)].map((_, index) => (
                <TableRow key={`skeleton-${index}`}>
                  <TableCell width="50%" sx={{ py: 2 }}>
                    <Skeleton variant="text" width={200} />
                  </TableCell>
                  <TableCell width="20%" sx={{ py: 2 }}>
                    <Skeleton variant="text" width={100} />
                  </TableCell>
                  <TableCell width="15%" sx={{ py: 2 }}>
                    <Skeleton variant="text" width={50} />
                  </TableCell>
                  <TableCell width="15%" sx={{ py: 2 }}>
                    <Skeleton variant="text" width={80} />
                  </TableCell>
                </TableRow>
              ))
            : documents.map((doc, index) => {
                const datetimeUploadTime = dayjs(doc?.uploadTime);
                const uploadTimeDate = datetimeUploadTime.format("DD/MM/YYYY");
                const uploadTimeTime = datetimeUploadTime.format("HH:mm:ss");

                return (
                  <TableRow key={index}>
                    <TableCell width="50%" sx={{ maxWidth: 0, py: 2 }}>
                      <Tooltip title={doc.name}>
                        <Typography variant="body2" fontSize={10} noWrap>
                          {doc.name}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell width="20%" sx={{ maxWidth: 0, py: 2 }}>
                      <Tooltip title={doc.uploadTime}>
                        <Stack>
                          {doc?.uploadTime && doc?.uploadTime !== "" ? (
                            <>
                              <Typography variant="body2" fontSize={10} noWrap>
                                {uploadTimeDate}
                              </Typography>
                              <Typography variant="body2" fontSize={10} noWrap>
                                {uploadTimeTime}
                              </Typography>
                            </>
                          ) : null}
                        </Stack>
                      </Tooltip>
                    </TableCell>
                    <TableCell width="15%" sx={{ maxWidth: 0, py: 2 }}>
                      <Tooltip title={doc.pages?.toString() || ""}>
                        <Typography variant="body2" fontSize={10} noWrap>
                          {doc.pages}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell width="15%" sx={{ maxWidth: 0, py: 2 }}>
                      <Tooltip title={computeStatusInFrench(doc.status)}>{displayedStatus(doc.status)}</Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function FolderDetails() {
  const ADDIN_BASE_URL = ADDIN_API_BASE_URL?.replace("-api", "") ?? "";
  const { folderId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [selectedSousDossierId, setSelectedSousDossierId] = React.useState("");

  const { data: folders } = useGetFoldersQuery();

  const { data: subFolders, isFetching: subFoldersLoading } = useGetSubFoldersQuery(folderId || "");

  const {
    data: currentSubFolderDetails,
    isFetching: subFolderDetailsLoading,
  } = useGetSubFolderDetailsQuery(
    folderId || "",
    subFolders?.find((sd) => sd?.sous_dossier_id === selectedSousDossierId)?.sous_dossier_id || "",
    {
      enabled: !!folderId && !!subFolders?.find((sd) => sd?.sous_dossier_id === selectedSousDossierId)?.sous_dossier_id,
      staleTime: 0,
    }
  );
  const currentFolder = useMemo(() => folders?.dossiers.find((f) => f.dossier_id === folderId), [folders, folderId]);
  const documents = useMemo(() => {
    if (!currentSubFolderDetails?.inputs) {
      return [];
    } else {
      return currentSubFolderDetails.inputs.map((doc: any) => ({
        name: doc?.input_classifier_rename ?? doc?.input_original_filename,
        uploadTime: new Date(doc.created_on).toLocaleString(),
        status: doc.step_classifier_status || "En attente",
        pages: doc?.page_number ?? "N/A",
      }));
    }
  }, [currentSubFolderDetails]);

  React.useEffect(() => {
    if (selectedSousDossierId === "" && subFolders) {
      setSelectedSousDossierId(subFolders?.[0]?.sous_dossier_id);
    }
  }, [subFolders, selectedSousDossierId, setSelectedSousDossierId]);

  const openExternalFiliation = (url: any) => {
    if (typeof url === "string") {
      Office.context.ui.openBrowserWindow(url);
      return;
    } else {
      console.error(`URL ${url} is invalid`);
      return;
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedSousDossierId(event.target.value as string);
  };

  const last_input_formatted_datetime =
    currentSubFolderDetails?.sous_dossier?.date_last_input &&
    currentSubFolderDetails?.sous_dossier?.date_last_input !== ""
      ? fDateTime(currentSubFolderDetails?.sous_dossier?.date_last_input)
      : null;

  return (
    <Stack height={0.92}>
      <Box sx={{ mb: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Button onClick={() => navigate(-1)} sx={{ position: "absolute", top: 20, right: 20 }}>
            Retour
          </Button>
        </Stack>

        <Typography color="secondary.500" fontWeight="bold" fontSize={14} noWrap>
          {`Dossier: ${currentFolder?.name} (${currentFolder?.dossier_id})`}
        </Typography>
      </Box>

      <Box sx={{ width: "100%", height: "100%", minHeight: 0 }}>
        {subFoldersLoading ? (
          <Stack justifyContent="center" alignItems="center" height="100%" width="100%">
            <CircularProgress />
          </Stack>
        ) : subFolders && subFolders.length > 0 ? (
          <Box sx={{ width: "100%" }}>
            <Stack sx={{ mb: 2, mt: 1 }}>
              <StyledSelect
                label={"Sous-dossier"}
                multiple={false}
                value={selectedSousDossierId}
                onChange={handleChange}
                size="small"
                labelProps={{
                  sx: {
                    color: theme.palette.text.secondary,
                  },
                }}
                sx={{
                  minWidth: 85,
                  color: "black",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: `1px solid rgba(145, 158, 171, 0.20)`,
                    borderRadius: 2,
                  },
                  "& .MuiSelect-select": {},
                }}
              >
                {subFolders.map((sf, index) => (
                  <MenuItem
                    key={sf.sous_dossier_id}
                    value={sf?.sous_dossier_id}
                    id={`menu-item-${index}`}
                  >{`${sf.nature} ${sf.name}`}</MenuItem>
                ))}
              </StyledSelect>
            </Stack>
            {subFolders
              .filter((sf) => sf?.sous_dossier_id === selectedSousDossierId)
              ?.map((_sf, _index) => (
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    width: "100%",
                    borderRadius: "4px",
                    height: "100%",
                    border: "1px solid",
                    borderColor: "rgba(135, 135, 135, 0.34)",
                    "& .MuiCardContent-root": {
                      p: 3,
                    },
                  }}
                >
                  <Box sx={{ p: 1 }}>
                    <Stack spacing={3}>
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ alignSelf: "flex-start", fontSize: 11 }}
                          disabled={subFolderDetailsLoading || !currentSubFolderDetails?.sous_dossier?.filiation_id}
                          onClick={() => {
                            if (currentSubFolderDetails?.sous_dossier?.filiation_id) {
                              navigate(
                                `/folder/${folderId}/filiation/${currentSubFolderDetails.sous_dossier.filiation_id}`
                              );
                            }
                          }}
                        >
                          Choisir les biens analysés
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ alignSelf: "flex-start", fontSize: 11 }}
                          disabled={
                            subFolderDetailsLoading ||
                            !currentSubFolderDetails?.sous_dossier?.filiation_id ||
                            !currentSubFolderDetails?.client_id
                          }
                          onClick={() => {
                            if (
                              currentSubFolderDetails.sous_dossier.filiation_id &&
                              currentSubFolderDetails?.client_id
                            ) {
                              openExternalFiliation(
                                `${ADDIN_BASE_URL}/taskpane.html#/filiation/${currentSubFolderDetails?.client_id}/${folderId}/${currentSubFolderDetails.sous_dossier.sous_dossier_id}/${currentSubFolderDetails.sous_dossier.filiation_id}`
                              );
                            }
                          }}
                        >
                          Visualiser le diagramme de filiation
                        </Button>
                      </Stack>

                      <Stack direction={"row"} spacing={2}>
                        <InfoWidget
                          title="Dernier ajout"
                          value={last_input_formatted_datetime ?? ""}
                          loading={subFolderDetailsLoading}
                        />
                        <InfoWidget
                          title="Nombre de documents déposés"
                          value={currentSubFolderDetails?.sous_dossier?.stat_number_of_inputs || 0}
                          loading={subFolderDetailsLoading}
                        />
                      </Stack>

                      <DocumentsTable documents={documents} loading={subFolderDetailsLoading} />
                    </Stack>
                  </Box>
                </Card>
              ))}
          </Box>
        ) : (
          <Typography color="text.secondary" textAlign="center">
            Aucun sous-dossier trouvé
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
